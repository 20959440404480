@keyframes ldio-ch3atfikf09 {
  0 % {
    transform: translate(2.01px, 2.01px);
  }
  33.33% {
    transform: translate(102.50999999999999px, 2.01px);
  }
  66.66% {
    transform: translate(42.209999999999994px, 102.50999999999999px);
  }
  100% {
    transform: translate(2.01px, 2.01px);
  }
}

.ldio-ch3atfikf09 > div {
  transform: scale(0.4);
  transform-origin: 100.49999999999999px 100.49999999999999px;
}

.ldio-ch3atfikf09 > div > div {
  animation: ldio-ch3atfikf09 1.25s linear infinite;
  position: absolute;
}

.ldio-ch3atfikf09 > div > div div:nth-child(1) {
  width: 72.35999999999999px;
  height: 72.35999999999999px;
  border-radius: 50%;
  border: 12.059999999999999px solid #e28c8c;
  background: #ffffff;
}

.ldio-ch3atfikf09 > div > div div:nth-child(2) {
  width: 17.084999999999997px;
  height: 51.254999999999995px;
  transform: rotate(-45deg);
  background: #e28c8c;
  border-radius: 0 0 8.04px 8.04px;
  position: absolute;
  top: 68.33999999999999px;
  left: 85.425px;
}

.loadingio-spinner-magnify-swk0sg3vnn {
  margin-left: auto;
  margin-right: auto;
  margin-top: -10px;
  width: 201px;
  height: 201px;
  display: inline-block;
  overflow: hidden;
  background: transparent;
}
.ldio-ch3atfikf09 {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}

.ldio-ch3atfikf09 div {
  box-sizing: content-box;
}

/* generated by https://loading.io/ */
